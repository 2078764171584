import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Picked from https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
const MAX_WIDTH = 90; // 1440px
const MIN_WIDTH = 40; // 640px

export function getResponsiveFontSize(
  { maxFontSize, minFontSize }: { maxFontSize: number; minFontSize: number },
  maxWidth: number = MAX_WIDTH,
  minWidth: number = MIN_WIDTH,
) {
  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);

  const yAxisIntersection = -minWidth * slope + minFontSize;

  const preferredValue = yAxisIntersection + slope * 100;

  const clamp = `clamp(${minFontSize}rem, ${yAxisIntersection}rem + ${preferredValue}vw, ${maxFontSize}rem)`;

  return clamp;
}
